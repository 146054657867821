.container {
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
  margin: 0 auto;
}

.section {
  padding-bottom: 60px;

  &:last-child {
    padding-bottom: 0;
  }
}
.popup, .popup--callme {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  z-index: 110;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    position: relative;
    width: 90%;
    max-width: 600px;
    margin: auto;
    background: white;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-40px);
    transition-property: transform;
    transition-duration: 0.3s;
    min-height: 450px;
    padding: 40px 20px;
    cursor: default;
  }

  &__close {
    @include bg--contain;

    background-image: url('../assets/icons/icon-close--blue.svg');
    transition-duration: 0.3s;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      transition-duration: 0.3s;
      transform: rotate(90deg);
      background-image: url('../assets/icons/icon-close--red.svg');
    }
  }

  &__content {
    text-align: left;
    width: 50%;

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  &__title {
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__subtitle {
    font-size: 16px;
    margin-bottom: 20px;
    color: $ares-gray-2;
    word-break: break-word;
  }

  &__image {
    @include bg--contain;

    background-image: url('../assets/images/expert-2--draw.png');
    height: 400px;
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  &__conditions {
    &__link {
      text-decoration: underline;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;

  &__input {
    border: 1px solid $ares-gray;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 10px 10px;
    line-height: 1;

    &::placeholder {
      color: $ares-gray-2;
    }

     &.valid {
      border-color: $ares-gray;
    }

    &.error {
      border-color: #f44336;
    }
  }

  label[for='upload-file'] {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #upload-file {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .btn {
    margin-bottom: 10px;
  }

  .cb-message {
    background-color: $ares-gray;
    padding: 10px 5px;
    border-radius: 3px;
    display: none;
    height: 0;
    text-align: center;
    margin-bottom: 10px;

    &--visible {
      display: block;
      height: auto;
    }
  }
}

.is-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;

  .popup__container {
    transform: translateY(0);
  }
}

.d-none {
  display: none;
}
body {
  @include Roboto--R;

  background-color: $ares-gray;
  color: $ares-black;
  font-size: 16px;
  overflow-x: hidden;
  margin: 0;
}

a {
  color: $ares-gray-2;
  text-decoration: none;
}

*,
::after,
::before {
  box-sizing: border-box
}

.d-flex {
  display: flex;
}

@media (prefers-reduced-motion:no-preference) {
  :root {
    scroll-behavior: smooth
  }
}

.btn {
  padding: 10px 20px;
  background-color: $ares-red;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid transparent;
  transition-duration: 0.3s;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    background-color: white;
    color: $ares-red;
    transition-duration: 0.3s;
  }
}

.btn--border-gray {
  border: 1px solid transparent;

  &:hover {
    border: 1px solid $ares-gray;
  }
}

.btn--disabled {
  background-color: $ares-gray-2;
  cursor: auto;

  &:hover {
    background-color: $ares-gray-2;
    color: white;
    border: 1px solid transparent;
  }
}

input, button {
  outline:none;
}

.no-scroll {
  overflow: hidden;
}
// @import './fonts';

// fonts
@mixin Roboto--L {
    font-family: 'Roboto L', sans-serif;
    font-weight: 300;
}

@mixin Roboto--R {
    font-family: 'Roboto R', sans-serif;
    font-weight: 400;
}

@mixin Roboto--M {
    font-family: 'Roboto M', sans-serif;
    font-weight: 500;
}

@mixin Roboto--B {
    font-family: 'Roboto B', sans-serif;
    font-weight: 700;
}

@mixin MuseoSansCyrl--L {
    font-family: 'MuseoSansCyrl L', sans-serif;
    font-weight: 300;
}

@mixin MuseoSansCyrl--R {
    font-family: 'MuseoSansCyrl R', sans-serif;
    font-weight: 500;
}

@mixin MuseoSansCyrl--B {
    font-family: 'MuseoSansCyrl B', sans-serif;
    font-weight: 700;
}

// colors
$ares-gray: #e6ebeb;
$ares-gray-2: #838d97;
$ares-red: #ff0032;
$ares-blue: #1e2d3c;
$ares-black: #2d2a2a;

// backgrounds
@mixin bg--center {
    background-repeat: no-repeat;
    background-position: center;
}

@mixin bg--cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin bg--contain {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

// buttons
@mixin btn--standart {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}
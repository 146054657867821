.dropdown {
  max-width: 210px;
  width: auto;
  flex: 0 0 auto;
  display: none;
  margin: 0 auto 20px;

  &--visible {
    display: block;
  }

  .dropdown__container {
    margin: 0 auto;
  }
}

/* Dropdown menu css */
.dropdown__container {
  position: relative;
  z-index: 100;
  max-width: 210px;

  .dropdown__toggle {
    color: $ares-black;
    background-color: white;
    font-size: 16px;
    padding: 10px 40px 10px 10px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    transition: all ease-in-out 0.3s;
    width: 210px;

    label {
      cursor: pointer;
      width: 100%;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $ares-blue;
      color: white;
    }
  }

  .dropdown__menu {
    width: 210px;
    border-radius: 3px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 100%;
    display: none;
    background-color: white;
    z-index: 10;
    margin-top: 0;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
//       overflow: hidden;
      border-radius: 3px;
      z-index: 10;
    }

    li {
      text-decoration: none;
      display: block;
      color: $ares-black;
      font-size: 14px;
      padding: 10px;
      font-weight: bold;
      box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
      transition: all ease-in-out 0.3s;

      &:hover,
      &:active,
      &:focus {
        background-color: $ares-blue;
        color: white;
      }
    }
  }
}

.dropdown__list {
  overflow-y: scroll;
  max-height: 220px;
}

.dropdown__menu,
.dropdown__toggle {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    border: 5px solid;
    border-top-color: rgba(0, 0, 0, 0);
    border-left-color: rgba(0, 0, 0, 0);
    margin-top: -2.5px;
    background-color: rgba(0, 0, 0, 0);
    transition: all ease-in-out 0.2s;
  }
}

.dropdown__menu {
  z-index: 10;
  position: relative;

  &::before {
    z-index: -1;
    transform: rotate(-135deg);
    top: -4px;
    border-color: white;
    box-shadow: 1px 1px 4px -2px rgba(0, 0, 0, 0.4);
    display: none;
  }
}

.dropdown__open {
  .dropdown__menu.dropdown__active {
    display: block;
  }
}

.dropdown__container.dropdown__open {
  .dropdown__toggle {
    background-color: $ares-blue;
    color: white;

    &:before {
      transform: rotate(-135deg);
    }
  }
}

// dropdown__bordered
.dropdown__bordered {
  .dropdown__toggle {
    border: 2px solid $ares-black;
    border-radius: 3px;

    &:hover,
    &:active,
    &:focus {
      background-color: $ares-blue;
      color: white;
      border-color: white;
    }
  }

  .dropdown__menu {
    border: 2px solid $ares-black;
    border-radius: 3px;

    &::before {
      border-color: $ares-black;
    }

    a {
      box-shadow: none;
      border-bottom: 2px solid $ares-black;
    }

    li {
      &:last-child {
        a {
          border-bottom: 0;
        }
      }
    }
  }
}

// dropdown__gradient

.dropdown__gradient {

  .dropdown__toggle,
  .dropdown__menu,
  a {
    background: rgb(255, 49, 49);
    background: -moz-linear-gradient(262deg, rgba(255, 49, 49, 1) 11%, rgba(84, 0, 255, 1) 100%);
    background: -webkit-linear-gradient(262deg, rgba(255, 49, 49, 1) 11%, rgba(84, 0, 255, 1) 100%);
    background: linear-gradient(262deg, rgba(255, 49, 49, 1) 11%, rgba(84, 0, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff3131", endColorstr="#5400ff", GradientType=1);
    color: white;
  }

  .dropdown__toggle {
    &::before {
      color: white;
    }
  }

  .dropdown__menu {
    a {
      background: none;
      box-shadow: none;
      color: white;

      &:hover,
      &:active,
      &:focus {
        background: rgb(255, 49, 49);
        background: -moz-linear-gradient(66deg, rgba(255, 49, 49, 1) 11%, rgba(84, 0, 255, 1) 100%);
        background: -webkit-linear-gradient(66deg, rgba(255, 49, 49, 1) 11%, rgba(84, 0, 255, 1) 100%);
        background: linear-gradient(66deg, rgba(255, 49, 49, 1) 11%, rgba(84, 0, 255, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff3131", endColorstr="#5400ff", GradientType=1);
      }
    }

    &::before {
      box-shadow: none;
      border-color: rgb(255, 49, 49);
    }
  }

}


// dropdown__solid

.dropdown__solid {

  .dropdown__toggle,
  .dropdown__menu {
    background-color: $ares-blue;
    color: white;
  }

  .dropdown__menu {

    a {
      color: white;

      &:hover,
      &:active,
      &:focus {
        background-color: white;
        color: $ares-black;
      }
    }

    &::before {
      border-color: $ares-blue;
    }
  }
}
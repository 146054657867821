.footer {
  background-color: $ares-blue;
  color: $ares-gray-2;
  font-size: 13px;

  .footer__container {
    display: flex;
    padding: 35px 15px;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .footer-col {
    display: flex;
    margin-right: 30px;

    @media screen and (max-width: 768px) {
      margin-right: 20px;
      margin-bottom: 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    &__text {
      word-break: break-word;
      
      &--link {
        color: $ares-gray-2;

        &:hover {
          color: white;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;

      &__item {
        margin-bottom: 5px;
      }

      &__item--flex {
        display: flex;

        &__item {
          @include bg--cover;

          width: 23px;
          height: 23px;
          margin-right: 8px;

          &--facebook {
            background-image: url('../assets/icons/icon-facebook.png');
          }

          &--telegram {
            background-image: url('../assets/icons/icon-telegram.png');
          }

          &--instagram {
            background-image: url('../assets/icons/icon-instagram.png');
          }

          &--youtube {
            background-image: url('../assets/icons/icon-youtube.png');
          }
        }
      }
    }

    &__list--info {
      .footer-col__list__item {
        margin-bottom: 10px;
      }
    }

    &__icon {
      @include bg--contain;
      margin-right: 5px;
      width: 20px;

      &--pin {
        height: 16px;
        background-image: url('../assets/icons/icon-pin.png');
      }

      &--phone {
        height: 14px;
        background-image: url('../assets/icons/icon-phone.png');
      }

      &--mail {
        height: 11px;
        background-image: url('../assets/icons/icon-mail.png');
      }
    }
  }

  .footer-col--links {
    margin-right: auto;
  }

  .footer-col--socials {
    .footer-col__list__item--flex {
      margin-bottom: 30px;
    }
  }
}
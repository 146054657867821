@font-face {
  font-family: "Roboto L", sans-serif;
  src: local("Roboto") url("../assets/fonts/Roboto/Roboto-Light.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto R", sans-serif;
  src: local("Roboto") url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto M", sans-serif;
  src: local("Roboto") url("../assets/fonts/Roboto/Roboto-Medium.ttf") format("ttf");
}
@font-face {
  font-family: "Roboto B", sans-serif;
  src: local("Roboto") url("../assets/fonts/Roboto/Roboto-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "MuseoSansCyrl L", sans-serif;
  src: local("MuseoSansCyrl") url("../assets/fonts/MyseoSans/MuseoSansCyrl-300.ttf") format("ttf");
}
@font-face {
  font-family: "MuseoSansCyrl R", sans-serif;
  src: local("MuseoSansCyrl") url("../assets/fonts/MyseoSans/MuseoSansCyrl-500.ttf") format("ttf");
}
@font-face {
  font-family: "MuseoSansCyrl M", sans-serif;
  src: local("MuseoSansCyrl") url("../assets/fonts/MyseoSans/MuseoSansCyrl-700.ttf") format("ttf");
}
.container {
  width: 100%;
  max-width: 1280px;
  padding: 0 15px;
  margin: 0 auto;
}

.section {
  padding-bottom: 60px;
}
.section:last-child {
  padding-bottom: 0;
}

body {
  font-family: "Roboto R", sans-serif;
  font-weight: 400;
  background-color: #e6ebeb;
  color: #2d2a2a;
  font-size: 16px;
  overflow-x: hidden;
  margin: 0;
}

a {
  color: #838d97;
  text-decoration: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.d-flex {
  display: flex;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
.btn {
  padding: 10px 20px;
  background-color: #ff0032;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid transparent;
  transition-duration: 0.3s;
  cursor: pointer;
  flex-shrink: 0;
}
.btn:hover {
  background-color: white;
  color: #ff0032;
  transition-duration: 0.3s;
}

.btn--border-gray {
  border: 1px solid transparent;
}
.btn--border-gray:hover {
  border: 1px solid #e6ebeb;
}

.btn--disabled {
  background-color: #838d97;
  cursor: auto;
}
.btn--disabled:hover {
  background-color: #838d97;
  color: white;
  border: 1px solid transparent;
}

input, button {
  outline: none;
}

.no-scroll {
  overflow: hidden;
}

.section--main__container {
  position: relative;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 980px) {
  .section--main__container {
    justify-content: center;
    flex-direction: column;
    height: auto;
  }
}
.section--main__title {
  font-size: 65px;
  font-weight: bold;
  max-width: 600px;
  z-index: 2;
  word-break: break-word;
  margin-bottom: 30px;
}
@media screen and (max-width: 1200px) {
  .section--main__title {
    font-size: 55px;
  }
}
@media screen and (max-width: 980px) {
  .section--main__title {
    text-align: center;
    margin: 30px auto;
  }
}
@media screen and (max-width: 768px) {
  .section--main__title {
    font-size: 40px;
  }
}
@media screen and (max-width: 480px) {
  .section--main__title {
    font-size: 30px;
  }
}
.section--main__image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 650px;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .section--main__image {
    max-width: 600px;
    min-width: 500px;
  }
}
@media screen and (max-width: 980px) {
  .section--main__image {
    height: 500px;
    min-width: auto;
  }
}
@media screen and (max-width: 768px) {
  .section--main__image {
    height: 300px;
  }
}
.section--main .form {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}
@media screen and (max-width: 980px) {
  .section--main .form {
    justify-content: center;
  }
}
.section--main .form .form__input {
  margin-right: 10px;
}
.section--main__arrow {
  display: flex;
  margin-bottom: 30px;
}
@media screen and (max-width: 980px) {
  .section--main__arrow {
    margin: 0 auto 30px;
    max-width: 500px;
  }
}
@media screen and (max-width: 480px) {
  .section--main__arrow {
    margin-bottom: 20px;
  }
}
.section--main__arrow__icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 80px;
  height: 80px;
  background-image: url("../assets/icons/icon-arrow--red.svg");
  margin-right: 20px;
}
@media screen and (max-width: 980px) {
  .section--main__arrow__icon {
    min-width: 70px;
    height: 70px;
  }
}
@media screen and (max-width: 480px) {
  .section--main__arrow__icon {
    min-width: 60px;
    height: 60px;
    margin-right: 15px;
  }
}
.section--main__arrow__text {
  font-size: 20px;
}
@media screen and (max-width: 480px) {
  .section--main__arrow__text {
    font-size: 18px;
  }
}

.category__container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
}
.category-top {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .category-top {
    flex-wrap: wrap;
  }
}
.category-top__title {
  font-size: 35px;
  font-weight: bold;
  display: flex;
  align-items: center;
  word-break: break-word;
}
@media screen and (max-width: 768px) {
  .category-top__title {
    font-size: 30px;
  }
}
@media screen and (max-width: 480px) {
  .category-top__title {
    font-size: 25px;
    margin-bottom: 10px;
  }
}
.category-list {
  display: flex;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .category-list {
    margin-bottom: 20px;
  }
}
.category-list__icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.category-list__icon--vahta {
  transition-duration: 0.3s;
  background-image: url("../assets/icons/icon-bus.png");
}
.category-list__icon--graphik {
  transition-duration: 0.3s;
  background-image: url("../assets/icons/icon-graphik.png");
}
.category-list__item {
  width: 50%;
  padding: 30px 12px;
  border-radius: 5px;
  margin-right: 30px;
  background-color: white;
  color: #1e2d3c;
  transition-duration: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .category-list__item {
    margin-right: 15px;
    padding: 20px 12px;
  }
}
@media screen and (max-width: 480px) {
  .category-list__item {
    margin-right: 10px;
  }
}
.category-list__item:hover {
  background-color: #1e2d3c;
  color: white;
  transition-duration: 0.3s;
}
.category-list__item:hover .category-list__icon--vahta {
  background-image: url("../assets/icons/icon-bus--white.png");
  transition-duration: 0.3s;
}
.category-list__item:hover .category-list__icon--graphik {
  background-image: url("../assets/icons/icon-graphik--white.png");
  transition-duration: 0.3s;
}
.category-list__item--active {
  background-color: #1e2d3c;
  color: white;
  transition-duration: 0.3s;
}
.category-list__item--active .category-list__icon--vahta {
  transition-duration: 0.3s;
  background-image: url("../assets/icons/icon-bus--white.png");
}
.category-list__item--active .category-list__icon--graphik {
  transition-duration: 0.3s;
  background-image: url("../assets/icons/icon-graphik--white.png");
}
.category-list__item:last-child {
  margin-right: 0;
}
.category-vacancies {
  display: none;
}
.category-vacancies .d-flex {
  width: 100%;
}
.category-vacancies--active {
  display: flex;
  flex-direction: column;
}
.category-vacancies__empty {
  display: none;
  text-align: center;
  padding: 20px;
  font-size: 24px;
}
.category-vacancies__empty--visible {
  display: block;
}
.category-vacancies__item {
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #838d97;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.category-vacancies__item--hide {
  display: none;
}
.category-vacancies__item-top {
  position: relative;
  padding: 30px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .category-vacancies__item-top {
    padding: 20px;
  }
}
.category-vacancies__item-top__arrow {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/icons/arrow--blue.svg");
  transition-duration: 0.3s;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
}
@media screen and (max-width: 480px) {
  .category-vacancies__item-top__arrow {
    top: 20px;
    right: 20px;
  }
}
.category-vacancies__item-top__arrow--open {
  background-image: url("../assets/icons/arrow--red.svg");
  transition-duration: 0.3s;
  transform: rotate(0deg);
}
.category-vacancies__item-top__title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #2d2a2a;
  position: relative;
  padding-right: 33px;
  word-break: break-word;
}
@media screen and (max-width: 480px) {
  .category-vacancies__item-top__title {
    font-size: 16px;
  }
}
.category-vacancies__item-top__subtitle {
  font-size: 18px;
  color: #838d97;
  word-break: break-word;
}
@media screen and (max-width: 480px) {
  .category-vacancies__item-top__subtitle {
    font-size: 14px;
  }
}
.category-vacancies__item-content {
  padding: 0 30px 30px;
  display: none;
  flex-direction: column;
  flex-wrap: wrap;
}
@media screen and (max-width: 480px) {
  .category-vacancies__item-content {
    padding: 0 20px 20px;
  }
}
.category-vacancies__item-content--show {
  display: flex;
}
.category-vacancies__item-content__block {
  border-radius: 5px;
  background-color: yellowgreen;
  padding: 30px 20px;
  height: auto;
  margin-bottom: 15px;
}
@media screen and (max-width: 480px) {
  .category-vacancies__item-content__block {
    padding: 20px 15px;
    margin-bottom: 10px;
  }
}
.category-vacancies__item-content__block__title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  word-break: break-word;
}
@media screen and (max-width: 480px) {
  .category-vacancies__item-content__block__title {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.category-vacancies__item-content__block__text {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  word-break: break-word;
}
@media screen and (max-width: 480px) {
  .category-vacancies__item-content__block__text {
    font-size: 12px;
    line-height: 1;
  }
}
.category-vacancies__item-content__block--full {
  width: 100%;
}
.category-vacancies__item-content__block--full--wi {
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
}
@media screen and (max-width: 768px) {
  .category-vacancies__item-content__block--full--wi {
    background-image: none;
    height: auto;
  }
}
.category-vacancies__item-content__block--half {
  width: 50%;
  margin-right: 15px;
}
.category-vacancies__item-content__block--half:nth-child(even) {
  margin-right: 0;
}
.category-vacancies__item-content__block--half--wi {
  min-height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
}
@media screen and (max-width: 768px) {
  .category-vacancies__item-content__block--half--wi {
    background-image: none;
    height: auto;
  }
}
.category-vacancies__item-content__btn {
  margin-top: 15px;
}
@media screen and (max-width: 480px) {
  .category-vacancies__item-content__btn {
    margin-top: 5px;
  }
}

.section--expert {
  height: 830px;
}
@media screen and (max-width: 1200px) {
  .section--expert {
    height: 600px;
  }
}
@media screen and (max-width: 980px) {
  .section--expert {
    height: 500px;
  }
}
@media screen and (max-width: 768px) {
  .section--expert {
    height: 400px;
  }
}
@media screen and (max-width: 480px) {
  .section--expert {
    padding: 50px 0;
  }
}

.expert__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  height: 100%;
}
.expert__block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  width: calc(50% - 40px);
  margin-left: 40px;
  flex-shrink: 0;
}
@media screen and (max-width: 768px) {
  .expert__block {
    width: 100%;
  }
}
.expert__block__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  word-break: break-word;
}
.expert__block__subtitle {
  font-size: 16px;
  margin-bottom: 40px;
  word-break: break-word;
}
.expert__block__btn {
  width: 180px;
}
.expert__image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 600px;
  width: 50%;
  height: 450px;
  background-image: url("../assets/images/bot-1.png");
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .expert__image {
    width: 0;
  }
}

.header {
  height: 100px;
  width: 100%;
  background-color: #e6ebeb;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .header {
    height: auto;
  }
}
.header .header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .header .header__container {
    justify-content: center;
    flex-direction: column;
  }
}

.logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 50px;
  background-image: url("../assets/icons/logo.png");
  margin-right: 50px;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .logo {
    margin-right: 0;
  }
}

.phone-block {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .phone-block {
    flex-direction: column;
  }
}
.phone-block__link {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
@media screen and (max-width: 768px) {
  .phone-block__link {
    margin-bottom: 10px;
  }
}
.phone-block__link__icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 28px;
  height: 28px;
  margin-right: 15px;
  background-image: url("../assets/icons/icon-phone--red.svg");
}
.phone-block__link__text {
  color: #2d2a2a;
  font-size: 24px;
  font-weight: bold;
}

.footer {
  background-color: #1e2d3c;
  color: #838d97;
  font-size: 13px;
}
.footer .footer__container {
  display: flex;
  padding: 35px 15px;
}
@media screen and (max-width: 768px) {
  .footer .footer__container {
    flex-wrap: wrap;
  }
}
.footer .footer-col {
  display: flex;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .footer .footer-col {
    margin-right: 20px;
    margin-bottom: 10px;
  }
}
.footer .footer-col:last-child {
  margin-right: 0;
}
.footer .footer-col__text {
  word-break: break-word;
}
.footer .footer-col__text--link {
  color: #838d97;
}
.footer .footer-col__text--link:hover {
  color: white;
}
.footer .footer-col__list {
  display: flex;
  flex-direction: column;
}
.footer .footer-col__list__item {
  margin-bottom: 5px;
}
.footer .footer-col__list__item--flex {
  display: flex;
}
.footer .footer-col__list__item--flex__item {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 23px;
  height: 23px;
  margin-right: 8px;
}
.footer .footer-col__list__item--flex__item--facebook {
  background-image: url("../assets/icons/icon-facebook.png");
}
.footer .footer-col__list__item--flex__item--telegram {
  background-image: url("../assets/icons/icon-telegram.png");
}
.footer .footer-col__list__item--flex__item--instagram {
  background-image: url("../assets/icons/icon-instagram.png");
}
.footer .footer-col__list__item--flex__item--youtube {
  background-image: url("../assets/icons/icon-youtube.png");
}
.footer .footer-col__list--info .footer-col__list__item {
  margin-bottom: 10px;
}
.footer .footer-col__icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 5px;
  width: 20px;
}
.footer .footer-col__icon--pin {
  height: 16px;
  background-image: url("../assets/icons/icon-pin.png");
}
.footer .footer-col__icon--phone {
  height: 14px;
  background-image: url("../assets/icons/icon-phone.png");
}
.footer .footer-col__icon--mail {
  height: 11px;
  background-image: url("../assets/icons/icon-mail.png");
}
.footer .footer-col--links {
  margin-right: auto;
}
.footer .footer-col--socials .footer-col__list__item--flex {
  margin-bottom: 30px;
}

.popup, .popup--callme {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  z-index: 110;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup__container, .popup--callme__container {
  position: relative;
  width: 90%;
  max-width: 600px;
  margin: auto;
  background: white;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-40px);
  transition-property: transform;
  transition-duration: 0.3s;
  min-height: 450px;
  padding: 40px 20px;
  cursor: default;
}
.popup__close, .popup--callme__close {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/icons/icon-close--blue.svg");
  transition-duration: 0.3s;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.popup__close:hover, .popup--callme__close:hover {
  transition-duration: 0.3s;
  transform: rotate(90deg);
  background-image: url("../assets/icons/icon-close--red.svg");
}
.popup__content, .popup--callme__content {
  text-align: left;
  width: 50%;
}
@media screen and (max-width: 480px) {
  .popup__content, .popup--callme__content {
    width: 100%;
  }
}
.popup__title, .popup--callme__title {
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 15px;
}
.popup__subtitle, .popup--callme__subtitle {
  font-size: 16px;
  margin-bottom: 20px;
  color: #838d97;
  word-break: break-word;
}
.popup__image, .popup--callme__image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/images/expert-2--draw.png");
  height: 400px;
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 480px) {
  .popup__image, .popup--callme__image {
    display: none;
  }
}
.popup__conditions__link, .popup--callme__conditions__link {
  text-decoration: underline;
}

.form {
  display: flex;
  flex-direction: column;
}
.form__input {
  border: 1px solid #e6ebeb;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 10px 10px;
  line-height: 1;
}
.form__input::placeholder {
  color: #838d97;
}
.form__input.valid {
  border-color: #e6ebeb;
}
.form__input.error {
  border-color: #f44336;
}
.form label[for=upload-file] {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form #upload-file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.form .btn {
  margin-bottom: 10px;
}
.form .cb-message {
  background-color: #e6ebeb;
  padding: 10px 5px;
  border-radius: 3px;
  display: none;
  height: 0;
  text-align: center;
  margin-bottom: 10px;
}
.form .cb-message--visible {
  display: block;
  height: auto;
}

.is-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}
.is-visible .popup__container {
  transform: translateY(0);
}

.d-none {
  display: none;
}

.dropdown {
  max-width: 210px;
  width: auto;
  flex: 0 0 auto;
  display: none;
  margin: 0 auto 20px;
}
.dropdown--visible {
  display: block;
}
.dropdown .dropdown__container {
  margin: 0 auto;
}

/* Dropdown menu css */
.dropdown__container {
  position: relative;
  z-index: 100;
  max-width: 210px;
}
.dropdown__container .dropdown__toggle {
  color: #2d2a2a;
  background-color: white;
  font-size: 16px;
  padding: 10px 40px 10px 10px;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  transition: all ease-in-out 0.3s;
  width: 210px;
}
.dropdown__container .dropdown__toggle label {
  cursor: pointer;
  width: 100%;
}
.dropdown__container .dropdown__toggle:hover, .dropdown__container .dropdown__toggle:active, .dropdown__container .dropdown__toggle:focus {
  background-color: #1e2d3c;
  color: white;
}
.dropdown__container .dropdown__menu {
  width: 210px;
  border-radius: 3px;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 100%;
  display: none;
  background-color: white;
  z-index: 10;
  margin-top: 0;
}
.dropdown__container .dropdown__menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 3px;
  z-index: 10;
}
.dropdown__container .dropdown__menu li {
  text-decoration: none;
  display: block;
  color: #2d2a2a;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
  transition: all ease-in-out 0.3s;
}
.dropdown__container .dropdown__menu li:hover, .dropdown__container .dropdown__menu li:active, .dropdown__container .dropdown__menu li:focus {
  background-color: #1e2d3c;
  color: white;
}

.dropdown__list {
  overflow-y: scroll;
  max-height: 220px;
}

.dropdown__menu,
.dropdown__toggle {
  position: relative;
}
.dropdown__menu::before,
.dropdown__toggle::before {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  border: 5px solid;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  margin-top: -2.5px;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.2s;
}

.dropdown__menu {
  z-index: 10;
  position: relative;
}
.dropdown__menu::before {
  z-index: -1;
  transform: rotate(-135deg);
  top: -4px;
  border-color: white;
  box-shadow: 1px 1px 4px -2px rgba(0, 0, 0, 0.4);
  display: none;
}

.dropdown__open .dropdown__menu.dropdown__active {
  display: block;
}

.dropdown__container.dropdown__open .dropdown__toggle {
  background-color: #1e2d3c;
  color: white;
}
.dropdown__container.dropdown__open .dropdown__toggle:before {
  transform: rotate(-135deg);
}

.dropdown__bordered .dropdown__toggle {
  border: 2px solid #2d2a2a;
  border-radius: 3px;
}
.dropdown__bordered .dropdown__toggle:hover, .dropdown__bordered .dropdown__toggle:active, .dropdown__bordered .dropdown__toggle:focus {
  background-color: #1e2d3c;
  color: white;
  border-color: white;
}
.dropdown__bordered .dropdown__menu {
  border: 2px solid #2d2a2a;
  border-radius: 3px;
}
.dropdown__bordered .dropdown__menu::before {
  border-color: #2d2a2a;
}
.dropdown__bordered .dropdown__menu a {
  box-shadow: none;
  border-bottom: 2px solid #2d2a2a;
}
.dropdown__bordered .dropdown__menu li:last-child a {
  border-bottom: 0;
}

.dropdown__gradient .dropdown__toggle,
.dropdown__gradient .dropdown__menu,
.dropdown__gradient a {
  background: rgb(255, 49, 49);
  background: -moz-linear-gradient(262deg, rgb(255, 49, 49) 11%, rgb(84, 0, 255) 100%);
  background: -webkit-linear-gradient(262deg, rgb(255, 49, 49) 11%, rgb(84, 0, 255) 100%);
  background: linear-gradient(262deg, rgb(255, 49, 49) 11%, rgb(84, 0, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff3131", endColorstr="#5400ff", GradientType=1);
  color: white;
}
.dropdown__gradient .dropdown__toggle::before {
  color: white;
}
.dropdown__gradient .dropdown__menu a {
  background: none;
  box-shadow: none;
  color: white;
}
.dropdown__gradient .dropdown__menu a:hover, .dropdown__gradient .dropdown__menu a:active, .dropdown__gradient .dropdown__menu a:focus {
  background: rgb(255, 49, 49);
  background: -moz-linear-gradient(66deg, rgb(255, 49, 49) 11%, rgb(84, 0, 255) 100%);
  background: -webkit-linear-gradient(66deg, rgb(255, 49, 49) 11%, rgb(84, 0, 255) 100%);
  background: linear-gradient(66deg, rgb(255, 49, 49) 11%, rgb(84, 0, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff3131", endColorstr="#5400ff", GradientType=1);
}
.dropdown__gradient .dropdown__menu::before {
  box-shadow: none;
  border-color: rgb(255, 49, 49);
}

.dropdown__solid .dropdown__toggle,
.dropdown__solid .dropdown__menu {
  background-color: #1e2d3c;
  color: white;
}
.dropdown__solid .dropdown__menu a {
  color: white;
}
.dropdown__solid .dropdown__menu a:hover, .dropdown__solid .dropdown__menu a:active, .dropdown__solid .dropdown__menu a:focus {
  background-color: white;
  color: #2d2a2a;
}
.dropdown__solid .dropdown__menu::before {
  border-color: #1e2d3c;
}
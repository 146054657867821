.section--main {

  &__container {
    position: relative;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 980px) {
      justify-content: center;
      flex-direction: column;
      height: auto;
    }
  }

  &__title {
    font-size: 65px;
    font-weight: bold;
    max-width: 600px;
    z-index: 2;
    word-break: break-word;
    margin-bottom: 30px;

    @media screen and (max-width: 1200px) {
      font-size: 55px;
    }

    @media screen and (max-width: 980px) {
      text-align: center;
      margin: 30px auto;
    }

    @media screen and (max-width: 768px) {
      font-size: 40px;
    }

    @media screen and (max-width: 480px) {
      font-size: 30px;
    }
  }

  &__image {
    @include bg--contain;

    // background-image: url('../assets/images/main-1.png');
    max-width: 650px;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;

    @media screen and (max-width: 1200px) {
      max-width: 600px;
      min-width: 500px;
    }

    @media screen and (max-width: 980px) {
      height: 500px;
      min-width: auto;
    }

    @media screen and (max-width: 768px) {
      height: 300px;
    }
  }

  .form {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;

    @media screen and (max-width: 980px) {
      justify-content: center;
    }

    .form__input {
      margin-right: 10px;
    }
  }

  &__arrow {
    display: flex;
    margin-bottom: 30px;

    @media screen and (max-width: 980px) {
      margin: 0 auto 30px;
      max-width: 500px;
    }

    @media screen and (max-width: 480px) {
      margin-bottom: 20px;
    }

    &__icon {
      @include bg--contain;
      min-width: 80px;
      height: 80px;
      background-image: url('../assets/icons/icon-arrow--red.svg');
      margin-right: 20px;

      @media screen and (max-width: 980px) {
        min-width: 70px;
        height: 70px;
      }

      @media screen and (max-width: 480px) {
        min-width: 60px;
        height: 60px;
        margin-right: 15px;
      }
    }

    &__text {
      font-size: 20px;

      @media screen and (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
}

.category {

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
  }

  &-top {
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }

    &__title {
      font-size: 35px;
      font-weight: bold;
      display: flex;
      align-items: center;
      word-break: break-word;

      @media screen and (max-width: 768px) {
        font-size: 30px;
      }

      @media screen and (max-width: 480px) {
        font-size: 25px;
        margin-bottom: 10px;
      }
    }
  }

  &-list {
    display: flex;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }

    &__icon {
      @include bg--contain;

      width: 30px;
      height: 30px;
      margin-right: 10px;

      &--vahta {
        transition-duration: 0.3s;
        background-image: url('../assets/icons/icon-bus.png');
      }

      &--graphik {
        transition-duration: 0.3s;
        background-image: url('../assets/icons/icon-graphik.png');
      }
    }

    &__item {
      width: 50%;
      padding: 30px 12px;
      border-radius: 5px;
      margin-right: 30px;
      background-color: white;
      color: $ares-blue;
      transition-duration: 0.3s;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 768px) {
        margin-right: 15px;
        padding: 20px 12px;
      }

      @media screen and (max-width: 480px) {
        margin-right: 10px;
      }

      &:hover {
        background-color: $ares-blue;
        color: white;
        transition-duration: 0.3s;

        .category-list__icon {
          &--vahta {
            background-image: url('../assets/icons/icon-bus--white.png');
            transition-duration: 0.3s;
          }

          &--graphik {
            background-image: url('../assets/icons/icon-graphik--white.png');
            transition-duration: 0.3s;
          }
        }
      }

      &--active {
        background-color: $ares-blue;
        color: white;
        transition-duration: 0.3s;

        .category-list__icon {
          &--vahta {
            transition-duration: 0.3s;
            background-image: url('../assets/icons/icon-bus--white.png');
          }

          &--graphik {
            transition-duration: 0.3s;
            background-image: url('../assets/icons/icon-graphik--white.png');
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-vacancies {
    display: none;

    .d-flex {
      width: 100%;
    }

    &--active {
      display: flex;
      flex-direction: column;
    }

    &__empty {
      display: none;
      text-align: center;
      padding: 20px;
      font-size: 24px;

      &--visible {
        display: block;
      }
    }

    &__item {
      margin-bottom: 30px;
      border-radius: 5px;
      border: 1px solid $ares-gray-2;
      display: flex;
      flex-direction: column;
      width: 100%;

      &--hide {
        display: none;
      }
    }

    &__item-top {
      position: relative;
      padding: 30px;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        padding: 20px;
      }

      &__arrow {
        @include bg--contain;

        background-image: url('../assets/icons/arrow--blue.svg');
        transition-duration: 0.3s;
        position: absolute;
        top: 30px;
        right: 30px;
        width: 20px;
        height: 20px;
        transform: rotate(180deg);

        @media screen and (max-width: 480px) {
          top: 20px;
          right: 20px;
        }

        &--open {
          background-image: url('../assets/icons/arrow--red.svg');
          transition-duration: 0.3s;
          transform: rotate(0deg);
        }
      }

      &__title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 12px;
        color: $ares-black;
        position: relative;
        padding-right: 33px;
        word-break: break-word;

        @media screen and (max-width: 480px) {
          font-size: 16px;
        }
      }

      &__subtitle {
        font-size: 18px;
        color: $ares-gray-2;
        word-break: break-word;

        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
      }
    }

    &__item-content {
      padding: 0 30px 30px;
      display: none;
      flex-direction: column;
      flex-wrap: wrap;

      @media screen and (max-width: 480px) {
        padding: 0 20px 20px;
      }

      &--show {
        display: flex;
      }

      &__block {
        border-radius: 5px;
        background-color: yellowgreen;
        padding: 30px 20px;
        height: auto;
        margin-bottom: 15px;

        @media screen and (max-width: 480px) {
          padding: 20px 15px;
          margin-bottom: 10px;
        }

        &__title {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 15px;
          word-break: break-word;

          @media screen and (max-width: 480px) {
            font-size: 16px;
            margin-bottom: 10px;
          }
        }

        &__text {
          font-size: 16px;
          font-weight: normal;
          line-height: 1.2;
          word-break: break-word;

          @media screen and (max-width: 480px) {
            font-size: 12px;
            line-height: 1;
          }
        }
      }

      &__block--full {
        width: 100%;

        &--wi {
          min-height: 200px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: right bottom;

          @media screen and (max-width: 768px) {
            background-image: none;
            height: auto;
          }
        }
      }

      &__block--half {
        width: 50%;
        margin-right: 15px;

        &:nth-child(even) {
          margin-right: 0;
        }

        &--wi {
          min-height: 150px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: right bottom;

          @media screen and (max-width: 768px) {
            background-image: none;
            height: auto;
          }
        }
      }

      &__btn {
        margin-top: 15px;

        @media screen and (max-width: 480px) {
          margin-top: 5px;
        }
      }
    }
  }
}

.section--expert {
  height: 830px;

  @media screen and (max-width: 1200px) {
    height: 600px;
  }

  @media screen and (max-width: 980px) {
    height: 500px;
  }

  @media screen and (max-width: 768px) {
    height: 400px;
  }

  @media screen and (max-width: 480px) {
    padding: 50px 0;
  }
}

.expert {
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    width: calc(50% - 40px);
    margin-left: 40px;
    flex-shrink: 0;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &__title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
      word-break: break-word;
    }

    &__subtitle {
      font-size: 16px;
      margin-bottom: 40px;
      word-break: break-word;
    }

    &__btn {
      width: 180px;
    }
  }

  &__image {
    @include bg--contain;
    max-width: 600px;
    width: 50%;
    height: 450px;
    background-image: url('../assets/images/bot-1.png');
    z-index: 1;

    @media screen and (max-width: 768px) {
      width: 0;
    }
  }
}
@font-face {
  font-family: "Roboto L", sans-serif;
  src: local("Roboto") url("../assets/fonts/Roboto/Roboto-Light.ttf")
    format("ttf");
}

@font-face {
  font-family: "Roboto R", sans-serif;
  src: local("Roboto") url("../assets/fonts/Roboto/Roboto-Regular.ttf")
    format("ttf");
}

@font-face {
  font-family: "Roboto M", sans-serif;
  src: local("Roboto") url("../assets/fonts/Roboto/Roboto-Medium.ttf")
    format("ttf");
}

@font-face {
  font-family: "Roboto B", sans-serif;
  src: local("Roboto") url("../assets/fonts/Roboto/Roboto-Bold.ttf")
    format("ttf");
}

@font-face {
  font-family: "MuseoSansCyrl L", sans-serif;
  src: local("MuseoSansCyrl") url("../assets/fonts/MyseoSans/MuseoSansCyrl-300.ttf")
    format("ttf");
}

@font-face {
  font-family: "MuseoSansCyrl R", sans-serif;
  src: local("MuseoSansCyrl") url("../assets/fonts/MyseoSans/MuseoSansCyrl-500.ttf")
    format("ttf");
}

@font-face {
  font-family: "MuseoSansCyrl M", sans-serif;
  src: local("MuseoSansCyrl") url("../assets/fonts/MyseoSans/MuseoSansCyrl-700.ttf")
    format("ttf");
}


.header {
  height: 100px;
  width: 100%;
  background-color: $ares-gray;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: auto;
  }

  .header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      justify-content: center;
      flex-direction: column;
    }
  }
}

  
.logo {
  @include bg--contain;
  
  width: 150px;
  height: 50px;
  background-image: url('../assets/icons/logo.png');
  margin-right: 50px;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
}

.phone-block {
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &__link {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
    }

    &__icon {
      @include bg--contain;
      width: 28px;
      height: 28px;
      margin-right: 15px;
      background-image: url('../assets/icons/icon-phone--red.svg');
    }

    &__text {
      color: $ares-black;
      font-size: 24px;
      font-weight: bold;
    }
  }
}